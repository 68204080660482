import { useState, useEffect, useRef } from "react";
import React from "react";
import {
    Row,
    Col,
    Form,
    Input,
    Select,
    InputNumber,
    Button,
    Modal,
    message,
    AutoComplete,
} from "antd";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import { useRecoilState } from "recoil";
import { Upload } from "antd";
import { commonAxios } from "../../../../utils/AxiosSettings";
import { useNavigate } from "react-router";
import { InboxOutlined, DownloadOutlined } from "@ant-design/icons";
import { factoryState } from "../../../../RecoilState/FactoryState";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
    fetchTimeZones,
    getAllFactoriesOfCompany,
} from "../../../../Adapters/FactoryAndCompanyCalls";
import { tokenState } from "../../../../RecoilState/tokenState";
import styles from "../../AdminDashboard.module.css";
import { userState } from "../../../../RecoilState/userState";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import { useFetchUserData } from "../../UserDashboard/UserAction/UserHooks/useFechUserDataAdmin";
import useFetchRegion from "../../RegionDashboard/RegionHooks/FetchRegionHook";
import AddEditDropdown from "../../../Utils/AddEditDropdown";
import {
    addDivision,
    editDivision,
    getDivisionDetails,
} from "../../../../Adapters/DivisionCalls";
import {
    extractTimeZone,
    getFullTimeZoneFormat,
    handleTimeZoneSearch,
} from "../../../../utils/TimeZoneFunctions/TimeZoneFunctions";
import UploadComponent from "../../../UploadComponent/UploadHeaderFile";
import DateTimeFormatter from "../../../Utils/DateTimeFormatter";
import { LoadingOverlay } from "../../../Utils/LoadingOverlay";

const { Dragger } = Upload;
const { Option } = Select;

const EditFactoryButton = ({ props, showText }) => {
    const [file, setFile] = useState(null);
    const [isFactoryModalVisible, setIsFactoryModalVisible] = useState(false);
    const [FactoryDataForm] = Form.useForm();
    const isFormChanged = useRef(false);
    const [user, setUser] = useRecoilState(userState);
    let navigate = useNavigate();
    const [factorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [factoryList, setFactoryList] = useRecoilState(FactoryData);
    const [token, setToken] = useRecoilState(tokenState);
    const [fileError, setFileError] = useState(null);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const { fetchUserData } = useFetchUserData();
    const { fetchRegionData } = useFetchRegion();
    const [divisionList, setDivisionList] = useState([]);
    const [uploadKey, setUploadKey] = useState(Date.now()); // Unique key for Dragger
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [handleUpload, setHandleUpload] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState();
    const [handleDropdown, setHandleDropdown] = useState({
        open: false,
        edit: false,
        editId: null,
        disableInput: true,
        refreshRegion: true,
    });
    const [fieldValue, setFieldValue] = useState({
        id: null,
        fieldrename: "",
        addField: "",
        newFieldName: "",
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        FactoryDataForm.setFieldsValue({ csv_file: fileList });
    }, [fileList]);

    const fetchDivisionData = () => {
        getDivisionDetails(props.Company_id).then((res) => {
            setDivisionList(res.data.message_body.Division);
        });
    };

    const RenameDivision = (id, name) => {
        handleFormChange();
        editDivision(id, fieldValue.fieldrename)
            .then((res) => {
                message.success(res.data.message);
                fetchDivisionData();
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    message.error(err.response.data.errorMessage);
                }
            });
    };

    useEffect(() => {
        if (isFactoryModalVisible) {
            fetchDivisionData();
            const fetchData = async () => {
                const data = await fetchTimeZones();
                setTimeZones(data);
            };

            fetchData();
        }
    }, [isFactoryModalVisible]);

    useEffect(() => {
        FactoryDataForm.setFieldsValue({
            Company: props?.Company,
            Code: props?.Code,
            Location: props?.Location,
            division: props?.division_id,
            Number: props?.Number,
            Factory_timezone: getFullTimeZoneFormat(
                timeZones,
                props?.Factory_timezone
            ),
            csv_file: props?.masterdata,
        });
        setFieldValue((prev) => ({
            ...prev,
            id: props?.division_id,
        }));
        setUploadedFiles(props?.masterdata);
    }, [props, timeZones]);

    function refreshData() {
        getAllFactoriesOfCompany(
            props.Company_id,
            token.access,
            user.role === "REGIONAL_ADMIN" && "region"
        ).then((res) => {
            setFactoryList(res.data.message_body.Factories);
        });
    }

    const showModal = () => {
        setIsFactoryModalVisible(true);
    };

    const handleDivisionValue = async (value) => {
        try {
            if (!fieldValue.id && fieldValue.newFieldName) {
                handleFormChange();
                await addDivision(fieldValue.newFieldName)
                    .then((res) => {
                        FactoryDataForm.setFieldValue(
                            "division",
                            res.data.message_body.id
                        );
                        setFieldValue((prev) => ({
                            ...prev,
                            fieldrename: "",
                            addField: "",
                            newFieldName: "",
                        }));
                        setHandleDropdown((prev) => ({
                            open: false,
                            edit: false,
                            editId: null,
                            disableInput: true,
                            refreshRegion: true,
                        }));
                        message.success(res.data.message);
                    })
                    .catch((error) => {
                        message.error(error?.response?.data?.errorMessage);
                        return;
                    });
            } else {
                if (props.division_id !== fieldValue.id) {
                    handleFormChange();
                }
                FactoryDataForm.setFieldValue("division", fieldValue.id);
            }
        } catch {
            return;
        }
    };

    const handleOk = async (eventName, actionType) => {
        handleDivisionValue().then(async () => {
            await handleFactoryDataFormSubmit();
            await fetchUserData();
            await fetchRegionData();
            updateEventMetrics(eventName, actionType);
        });
    };

    const handleCancel = () => {
        setIsFactoryModalVisible(false);
        refreshFields();
        setFileList([]);
        setFile(null);
        setUploadKey(Date.now());
    };

    const DeleteFunction = async () => {
        try {
            const formData = {
                model: "factory",
                model_id: props.id,
                file_id: props?.masterdata,
            };

            await commonAxios.delete(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/upload/`,
                {
                    data: formData,
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    function refreshFields() {
        FactoryDataForm.setFieldsValue({
            Company: props.Company,
            Code: props.Code,
            Location: props.Location,
            Number: props.Number,
            Region: props.Region,
            Factory_timezone: getFullTimeZoneFormat(
                timeZones,
                props?.Factory_timezone
            ),
        });
    }

    const handleFactoryDataFormSubmit = async () => {
        setLoading(true);
        const processWithoutFile = async () => {
            const submitData = {
                Company: props.Company_id,
                Code: FactoryDataForm.getFieldValue("Code"),
                Location: FactoryDataForm.getFieldValue("Location"),
                Number: FactoryDataForm.getFieldValue("Number"),
                division: FactoryDataForm.getFieldValue("division"),
                // Region: FactoryDataForm.getFieldValue("Region"), not required for RA and SA
                requiredAwarenessProgram: FactoryDataForm.getFieldValue(
                    "requiredAwarenessProgram"
                ),
                Factory_timezone: extractTimeZone(
                    FactoryDataForm.getFieldValue("Factory_timezone")
                ),
            };

            try {
                const response = await commonAxios.put(
                    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/factories/?id=${props.id}`,
                    submitData
                );
                if (response.status === 200) {
                    message.success(response.data.message);
                } else {
                    message.error(
                        "An error occurred while processing your request."
                    );
                    refreshData();
                }
            } catch (error) {
                message.error(
                    error?.response?.data?.errorMessage || "An error occurred."
                );
                refreshData();
            }
        };

        if (fileList.length > 0) {
            await processWithoutFile(); // Process other form data first.

            const formData = new FormData();
            formData.append("csv_file", fileList[0].originFileObj);
            formData.append("Company", factorySelected.Company_id);
            formData.append("Factory", props.id);

            try {
                const response = await commonAxios.post(
                    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/complainers/`,
                    formData
                );
                message.success(response?.data?.message);
                if (props?.masterdata) {
                    DeleteFunction();
                    setHandleUpload(true);
                } else {
                    setHandleUpload(true);
                }
            } catch (error) {
                message.error(error?.response?.data?.errorMessage);
            } finally {
                setLoading(false);
            }
        } else {
            processWithoutFile();
        }
        setLoading(false);
    };

    function handleFormChange() {
        isFormChanged.current = true;
    }

    // const onFileChange = (info) => {
    //     const fileList = info.fileList;
    //     console.log(info.fileList);
    //     if (fileList.length > 0) {
    //         const file = fileList[0].originFileObj;
    //         setFile(file);
    //     } else {
    //         setFile(null);
    //         setFileError(null); // Reset the error when no file is selected
    //     }
    // };
    const onUploadComplete = () => {
        setFileList([]);
        setIsFactoryModalVisible(false);
        refreshData();
    };
    const downloadHandler = async () => {
        const apiUrl = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/sampleMasterdata`;

        try {
            // Make the API request using Axios
            const response = await commonAxios.get(apiUrl, {
                responseType: "blob",
            });

            message.info("The Sample temeplat CSV is being Downloaded");
            // Trigger the download by creating a temporary link element
            const downloadUrl = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = `sampleMasterData.csv`;
            link.click();
        } catch (error) {
            // Handle any network or other errors
            message.error(`Request Error: ${error.message}`);
        }
    };
    const handleSearch = (value) => {
        handleTimeZoneSearch(timeZones, value, setTimeZoneOptions);
    };

    return (
        <>
            <Button
                icon={
                    showText ? (
                        <div
                            style={{
                                color: "white",
                                padding: "13px 10px",
                            }}>
                            Edit Details
                        </div>
                    ) : user.role == "LEAD_SUPERVISOR" ? (
                        <EyeOutlined />
                    ) : (
                        <EditOutlined />
                    )
                }
                type="text"
                onClick={showModal}></Button>
            <Modal
                title="Edit Factory"
                open={isFactoryModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    user?.group_permissions?.includes("change_factory") && (
                        <Button
                            key="cancel"
                            loading={loading}
                            onClick={handleCancel}
                            className={styles.outlineButton}>
                            Cancel
                        </Button>
                    ),
                    user?.group_permissions?.includes("change_factory") && (
                        <Button
                            key="ok"
                            loading={loading}
                            onClick={() =>
                                handleOk(
                                    "editFactory",
                                    "Edit Factory- Save Changes"
                                )
                            }
                            className={styles.solidButton}>
                            Save Change
                        </Button>
                    ),
                ]}
                width={1400}
                closeIcon>
                <div>
                    {loading && <LoadingOverlay text="Processing..." />}
                    <Form
                        name="User Detail Form"
                        form={FactoryDataForm}
                        disabled={loading}
                        onValuesChange={handleFormChange}
                        onFinish={handleFactoryDataFormSubmit}
                        initialValues={props}>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item label="Company" name="Company">
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Factory Number"
                                    name="Code"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Factory Number",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9]+$/,
                                            message:
                                                "Factory Number must be alphanumeric",
                                        },
                                    ]}>
                                    <Input
                                        disabled={
                                            ![
                                                "SUPER_ADMIN",
                                                "REGIONAL_ADMIN",
                                            ].includes(user.role)
                                        }
                                        maxLength={10}></Input>
                                </Form.Item>

                                <Form.Item
                                    label="Division"
                                    name="Division"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select a Division",
                                        },
                                    ]}>
                                    <AddEditDropdown
                                        selectDisabled={
                                            !user?.group_permissions?.includes(
                                                "add_factory"
                                            )
                                        }
                                        fieldValue={fieldValue}
                                        setFieldValue={setFieldValue}
                                        handleDropdown={handleDropdown}
                                        setHandleDropdown={setHandleDropdown}
                                        fields={divisionList}
                                        RenameField={RenameDivision}
                                        allowSpaces={false}
                                    />
                                </Form.Item>
                            </Col>
                            {user?.group_permissions?.includes(
                                "change_factory"
                            ) && (
                                <Col style={{ width: "50%" }}>
                                    <Form.Item
                                        label="Inache Number"
                                        name="Number"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select an Inache Number",
                                            },
                                        ]}>
                                        <Input
                                            value={props?.Number}
                                            disabled></Input>
                                    </Form.Item>
                                    <Form.Item
                                        label="Upload Master Data"
                                        name="csv_file"
                                        help={fileError} // This will display the error message
                                        validateStatus={
                                            fileError ? "error" : ""
                                        } // This changes the outline color to red if there's an error
                                    >
                                        <UploadComponent
                                            document_delete_on={true}
                                            document_download_on={true}
                                            disableUpload={false}
                                            externalFileList={fileList}
                                            onExternalFileListChange={(
                                                newFileList
                                            ) => setFileList(newFileList)}
                                            Files={[]}
                                            maxFiles="1"
                                            companyFk={user.company_fk}
                                            model="factory"
                                            allowedFileTypes={["csv"]}
                                            modelMethod="new"
                                            onModelCreated={handleUpload}
                                            setOnModalCreated={setHandleUpload}
                                            id={props?.id}
                                            onUploadComplete={onUploadComplete}
                                            showAsANamedHyperLink={false}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Location"
                                    name="Location"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Location",
                                        },
                                    ]}>
                                    <Input
                                        disabled={
                                            user.role === "SUPER_ADMIN" ||
                                            user.role === "REGIONAL_ADMIN"
                                                ? false
                                                : true
                                        }
                                        value={props?.Location}
                                    />
                                </Form.Item>
                            </Col>
                            {user?.group_permissions?.includes(
                                "change_factory"
                            ) && (
                                <Col
                                    style={{
                                        width: "50%",
                                    }}>
                                    <Button
                                        className={styles.outlineButton}
                                        onClick={() => {
                                            downloadHandler();
                                        }}>
                                        <DownloadOutlined />
                                        Download Sample Template
                                    </Button>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Time Zone"
                                    name="Factory_timezone"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select a Time zone",
                                        },
                                    ]}>
                                    <AutoComplete
                                        disabled={!timeZones.length}
                                        placeholder="Enter Time zone"
                                        options={timeZoneOptions}
                                        onSearch={handleSearch}
                                        dropdownRender={(menu) => (
                                            <div className="autocomplete-dropdown">
                                                {menu}
                                            </div>
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Previous doc"
                                    name="previousdoc">
                                    <UploadComponent
                                        document_delete_on={false}
                                        document_download_on={false}
                                        disableUpload={false}
                                        externalFileList={fileList}
                                        onExternalFileListChange={(
                                            newFileList
                                        ) => setFileList(newFileList)}
                                        Files={uploadedFiles}
                                        maxFiles="1"
                                        companyFk={user.company_fk}
                                        model="factory"
                                        allowedFileTypes={["csv"]}
                                        modelMethod="new"
                                        onModelCreated={handleUpload}
                                        setOnModalCreated={setHandleUpload}
                                        id={props?.id}
                                        onUploadComplete={onUploadComplete}
                                        showAsANamedHyperLink={true}
                                    />
                                    <div style={{ textAlign: "start" }}>
                                        Uploaded on:{" "}
                                        {
                                            <DateTimeFormatter
                                                dateTimeString={
                                                    props?.masterdata_date
                                                }
                                                requiredField="date"
                                            />
                                        }
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default EditFactoryButton;
