import React, { useEffect, useState } from "react";
import styles from "../../CaseReport/CaseReport.module.css";
import { Link } from "react-router-dom";
import InPersonOfflineCaseUpload from "./InPersonOfflineCaseUpload";
import SuggestionBoxOfflineCaseUpload from "./SuggestionBoxOfflineCaseUpload";
import WorkerCommitteeOfflineCaseUpload from "./WorkerCommitteeOfflineCaseUpload";
import { Tabs } from "antd";
import { getDepartmentData } from "../../../Adapters/BroadcastmessageCalls";
import { userState } from "../../../RecoilState/userState";
import { useRecoilState } from "recoil";
import LoggedInComponent from "../../../containers/LoggedInComponent/LoggedInComponent";
export const getUniqueDepartments = (data) => {
    const uniqueDepartments = [
        ...new Set(data.map((item) => item.Department)),
    ].map((Department) => ({
        label: Department,
        value: Department,
    }));
    return uniqueDepartments;
};
const OfflineCaseUpload = () => {
    const [activeTab, setActiveTab] = useState("1");
    const [departmentValues, setDepartmentValues] = useState([]);
    const [user] = useRecoilState(userState);
    const callDepartmentDataApi = async () => {
        try {
            const res = await getDepartmentData(user?.factory_fk);
            if (res?.data?.message_body?.departments) {
                const uniqueDepartments = getUniqueDepartments(
                    res.data.message_body.departments
                );
                setDepartmentValues(uniqueDepartments);
            } else {
                console.error("Invalid response structure", res);
            }
        } catch (error) {
            console.error("Error fetching department data", error);
        }
    };

    useEffect(() => {
        callDepartmentDataApi();
    }, [user.factory_fk]);

    const items = [
        {
            key: "1",
            label: `In-Person`,
            children: (
                <InPersonOfflineCaseUpload
                    departmentValues={departmentValues}
                />
            ),
        },
        {
            key: "2",
            label: `Worker Committee`,
            children: (
                <WorkerCommitteeOfflineCaseUpload
                    departmentValues={departmentValues}
                />
            ),
        },
        {
            key: "3",
            label: `Suggestion Box `,
            children: (
                <SuggestionBoxOfflineCaseUpload
                    departmentValues={departmentValues}
                />
            ),
        },
    ];

    return (
        <LoggedInComponent>
            <div className={styles.casereportContainer}>
                <div className={styles.mainContainer}>
                    <div className={styles.header}>
                        <div className={styles.caseReportContainer}>
                            <Link to={"/home/OfflineCases"}>
                                <img
                                    src="/assets/images/back/Group 4495.svg"
                                    alt="back-logo"
                                />
                            </Link>
                            <span>Case Upload</span>
                        </div>
                    </div>
                    <div className={styles.bottomContainer}>
                        <Tabs
                            defaultActiveKey="1"
                            activeKey={activeTab}
                            items={items}
                            onChange={(key) => setActiveTab(key)}
                            style={{ width: "100%", padding: "20px" }}
                        />
                    </div>
                </div>
            </div>
        </LoggedInComponent>
    );
};

export default OfflineCaseUpload;
